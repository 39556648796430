const Strings = {
	today : "Today",
	boxes : "Boxes",
	journal : "Lab Book",
	settings : "Settings",
    teams : "Teams",
	email_is_missing : "Email is missing",
	please_input_an_email_address : "Please input an email address",
	password_is_missing : "Password is missing",
	password_confirmation_is : "Password confirmation is missing",
	passwords_must_match : "Passwords must match",
	sorry_cannot_submit_this_form : "Sorry, cannot submit this form at the moment",
	user_denied_permissions : "User denied permissions!",
	sorry_the_invite_code_did_not : "Sorry, the invite code did not correspond to any team",
	this_method_should_only_be : "This method should only be called when user is authenticated",
	welcome_to_xb : "By clicking on any of these boxes, below, you can take a look at each of the experiments in each box.",
	pick_an_experiment : "Pick an Experiment",
	day: "Day",
    days: "Days",
	there_is_nothing_to_do_for : "There is nothing to do for this day",
	congratulations_you_have : "Congratulations! You have completed this experiment.",
	redo_experiment : "Redo experiment",
	about_this_study : "About this study",
	are_you_sure : "Are you sure?",
	this_action_is_irreversible : "This action is irreversible",
	cancel : "Cancel",
	ok : "OK",
	about : "About",
	app_theme : "App theme",
	log_out : "Log out",
	you_have_requested_your : "You have requested your account to be deleted. We will be in touch soon.",
	sorry_your_account_cannot_be : "Sorry, your account cannot be deleted at the moment.",
	delete_your_account : "Delete your account",
	your_profile : "Your Profile",
	invite_code_is_missing : "Invite code is missing",
	invite_code_must_have_6 : "Invite code must have 6 digits",
	join_a_team : "Join a team",
	invite_code : "Invite code",
	an_invite_code_is_a_unique : "An invite code is a unique 6-digit code assigned to each team. Ask the owner, or any member of the team you want to join to continue.",
	team_name_is_missing : "Team name is missing",
	create_a_team : "Create a team",
	name : "Name",
	the_blueberries : "The Blueberries",
	description : "Description",
	your_amazing_team_description : "Your amazing team description",
	once_this_team_is_created_you : "Once this team is created, you can start inviting people by giving them the invite code.",
	you_are_currently_not_in_a : "You are currently not in a team",
	join : "Join",
	or : "Or",
	create_one : "Create one",
	you_will_be_able_to_join_this : "You will be able to join this team again",
	welcome : "Welcome",
	no_description : "No description",
	members : "Members",
	member_s_ : "member(s)",
	teams_leaderboard : "Teams leaderboard",
	leaderboard : "Leaderboard",
	leave_team : "Leave team",
	this_feature_is_coming_soon : "This feature is coming soon",
	stay_tuned : "Stay Tuned",
	you : "You",
	owner : "Owner",
	journal_tabs : "Journal tabs",
	you_did_not_have_any_notes : "You did not have any notes for this day",
	you_did_not_have_any : "You did not have any activities for this day",
	add_a_note : "Add a note",
	note : "Note",
	a_note_to_your_future_self : "A note to your future self",
	a_summary_of_what_you_have : "A summary of what you have done so far",
	daily : "Daily",
	weekly : "Weekly",
	activity : "Activity",
	your_meal_today_consisted_of : "Your meal today consisted of",
	your_meal_today_consisted_of_2 : "% green and",
	your_meal_today_consisted_of_3 : "% red.",
	sorry_we_do_not_have_any_data : "Sorry, we do not have any data for now",
	feel_free_to_jot_down : "Feel free to jot down anything that you want to look back for this day. It is for your use only, and feel free to edit it anytime.",
	next : "Next",
	scroll_or_read_to_the_end_to : "Scroll or read to the end to continue",
	please_check_this_box_to : "Please check this box to continue",
	enroll : "Enroll!",
	just_a_few_things : "Just a Few Things...",
	i_have_read_the_provided : "I have read the provided participant information and consent to take part in the study",
	i_am_a_member_of_staff_or_a : "I am a member of staff or a student at the University of Southampton, and I am at least 18 years old.",
	i_understand_that_i_must_be : "I understand that I must be employed in a participating faculty or service, and that in some faculties or services I should discuss or raise participation with my line manager, as directed by my faculty or service.",
	i_understand_that_physical : "I understand that physical activity can pose the risk of injury, and I have checked that it is safe for me to take part",
	you_have_successfully_reset : "You have successfully reset your password!",
	new_password : "New password",
	repeat_new_password : "Repeat new password",
	you_must_register_with_a : "You must register with a valid ",
	you_must_register_with_a_2 : " email address",
	this_email_is_associated_with : "This email is associated with an account. Please log in instead",
	register_to_continue : "Register to continue",
	register : "Register",
	university_email : "University email",
	password : "Password",
	repeat_password : "Repeat password",
	already_had_an_account_login : "Already had an account? Login",
	email_or_password_did_not : "Email or password did not match our record",
    login_error : "An error occurred while attempting to login, please check your credentials and try again.",
    login_to_continue : "Login to continue",
	login : "Login",
	email : "Email",
	not_yet_a_user_register : "Not yet a user? Register",
	forgotten_password : "Forgotten password?",
	reset_your_password : "Reset your password",
	if_your_email_is_in_our : "If your email is in our records, we will send you an email",
	continue : "Continue",
	confirm_your_account : "Confirm your account",
	sorry_we_cannot_confirm_this : "Sorry, we cannot confirm this account. You have either confirmed your account, or have waited too long to confirm this email. Please login with your registered credentials and follow the instructions.",
	account_confirmed_you_can_now : "Account confirmed. You can now continue in the app",
	we_have_sent_you_an_email_to : "We have sent you an email to confirm your account",
	resend_confirmation_email : "Resend confirmation email",
	confirmed_login : "Confirmed? Login",
	percent_completed : "% completed",
	day_s_ : "Day(s)",
	xb_app_logo : "XB App logo",
	you_havent_got_any_task_today : "You haven't got any tasks today",
	explore_what_you_can_do_in : "Tasks will appear here when you have started some experiments",
	todays_experiments : "Today's Experiments",
	read : "Read",
	less : "less",
	more : "more",
	your_first_name_is_missing : "Your first name is missing",
	your_last_name_is_missing : "Your last name is missing",
	department_is_missing : "Department is missing",
	lets_roll : "Lets roll!",
	first_name : "First name",
	last_name : "Last name",
	department : "Department",
	campus : "Campus",
	office : "Office",
	exercise_is_safe_and : "Exercise is safe and beneficial for most people, but some people should check with their doctor before changing their physical activity patterns. Use the",
	par_questionnaire : "PAR Questionnaire",
	exercise_is_safe_and_2 : "and/or consult your GP before engaging in physical activity.",
	what_move_do_you_want_to_do : "What move do you want to do?",
	click_the_button_below_to_add : "Click the button below to add a movement, then time yourself doing it. Or if you have done it in on your own, tap Next. Repeat 4 to 8 times if possible.",
	add : "Add",
	tap_on_the_time_display_to : "Tap on the time display to adjust time",
	a_minute_has_passed : "A minute has passed",
	youtube_video_player : "YouTube video player",
	this_field_must_be_a_number : "This field must be a number",
	this_field_is_missing : "This field is missing",
	you_need_to_time_this : "You need to time this activity!",
	this_field_must_be_checked : "This field must be checked",
	retake_another_picture : "Retake another picture",
	take_a_picture : "Take a picture",
	please_review_the_estimation : "Please review the estimation. Note that the percentages do not necessarily add up to 100 as ingredients like rice counts neither as red nor green",
	a_good_picture_must_show_your : "A good picture must show your meal as a whole. Try not to get other objects in the picture to get an accurate estimation",
	green : "Green (%)",
	red : "Red (%)",
	the_original_picture_has_been : "The original picture has been re-coloured for you to see if the estimation is correct. Feel free to modify the estimations",
	heart_rate_measure : "Heart rate measure illustration",
	find_your_pulse_by_firmly : "Find your pulse by firmly gripping your wrist like in the diagram below. Count your heartbeats for 20 seconds. Then, multiply by 3.",
	submit : "Submit",
	back : "Back",
	close : "Close",
	please_select : "Please select",
	password_must_have_at_least : "Password must have at least",
	password_must_have_at_least_2 : "characters",
	password_can_only_have_at : "Password can only have at most",
	password_can_only_have_at_2 : "characters",
    subscribe_to_complete_tasks: " Subscribe to this experiment to complete tasks ",
    confirm_restart_subscription: "Are you sure you want to restart this subscription?. You will lose all progress with your current subscription!",
    confirm_experiment_selection: "Do you want to subscribe to these selected experiments: ",
    confirm_experiment_subscription: "Do you want to subscribe to this box ?",
    subscribe_to_box: "Like the look of this box? Subscribe to join in!",
    already_subscribed: "You are subscribed to this box",
    confirm_subscription: "Confirm Subscription",
	confirm_resubscription: "Confirm Resubscription",
    week: "Week",
	click_here_to_find_some: "Click Here to find some!",
	box_description1: "A set of experiments to explore how you \"",
	box_description2: "\"!",
    experiment_type: "Experiment Type",
    experiment_duration: "Experiment Duration",
	whats_next: "What's next?",
	repeat_experiment: "Repeat Experiment",
	next_experiment: "Start the Next Experiment",
    active_experiments: "ACTIVE EXPERIMENTS",
    suggested_experiments: "SUGGESTED EXPERIMENTS",
    available_experiments: "CLICK any of these BOXES to see Each Week’s Experiment",
    completed_experiments: "COMPLETED EXPERIMENTS",
    scheduled_experiments: "SCHEDULED EXPERIMENTS",
    no_experiments_in_category: "You do not have any experiments in this category at the moment",
    days_long: "days long",
    starting_on: "Starting On",
    of: "of",
    not_subscribed_to_experiment: "You are not currently subscribed to this experiment.",
    has_cohort_code: "Do you have a cohort code ?",
    cohort_code: "Cohort code",
    cohort_code_required: "You have indicated that you have a cohort code. Please enter it before you can proceed.",
    cohort_code_invalid: "A cohort does not exist for the code you have provided. Please check the code and try again.",
    overview: 'OVERVIEW',
    prep: 'PREPARATIONS',
    steps: 'STEPS',
    reflections: 'REFLECTIONS',
    checks: 'CHECKS',
    tips: 'TIPS',
    preview: 'PREVIEW of '
};

export default Strings;
